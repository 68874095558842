<!-- 课程基础 -->
<template>
  <div class='basics-container'>
    <h1>理论基础</h1>
    <div class="container">
      <div class="item">
        <img src="../../assets/images/img5.png" alt="">
        <div class="info">
          <p class="t1">多元智能理论</p>
          <p class="text">
            多元智能理论认为，每个人通常具有语言、数理逻辑、视觉空间、身体运动、音乐节奏、人际关系、自我反省等智能。由于人们生长的家庭、文化和社区影响其智能的发展，因而人与人之间无论是在能能组合的方式上还是在所受到的实际训练上都存在着差异性，每个人在智能强项和优势方面存在着一定的差别。因此，幼儿园课程要为幼儿提供各种各样的机会来促进各种智能的发展，针对不同幼儿智能发展的特点，开发每个幼儿多元智能的发展潜能0。
          </p>
        </div>
      </div>
      <div class="item">
        <img src="../../assets/images/img6.png" alt="">
        <div class="info">
          <p class="t1">建构主义学习理论</p>
          <p class="text">
            建构主义学习理论认为，知识是个体与他人经由磋商并达成一致的社会建构，知识的建构是通过儿童的积极活动从自己内部获得的，而不是通过感觉从外部世界转换而来的。因此，幼儿不是通过教师的讲解与传授来被动地接受知识，而必须主动地参与到整个学习过程，根据其先前的经验主动地建构他们的知识世界，主动地获得新知识的意义。幼儿园课程要创设适宜幼儿学习的探究主题以及相关的问题情景，以创造适宜幼儿主动探究的学习环境，让幼儿能够积极主动地建构知识的意义。
          </p>
        </div>
      </div>
      <div class="item">
        <img src="../../assets/images/img7.png" alt="">
        <div class="info">
          <p class="t1">活动课程理论</p>
          <p class="text">
            活动课程理论认为，课程是从儿童的兴趣和需要出发，学校科目相互联系的真正中心是儿童本身的社会活动，教育要重视儿童本身的能力和生活体验，让儿童在活动中获得发展，因而非常注重儿童自己的活动构成，特别强调儿童的实践活动，倡导儿童通过活动获得经验、培养兴趣、解决问题、发展能力。幼儿园课程要以幼儿为中心，提供幼儿感兴趣的、接近幼儿现实生活的活动内容，以促使幼儿自愿、积极、主动地参与，让幼儿在"行动"中思考，在体验、实践、探究中不断获得知识、发现和创造，从而促进幼儿的健康发展。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {

    };
  },
  //方法集合
  methods: {

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
}
</script>
<style lang='less' scoped>
.basics-container {
  margin-bottom: 146px;
  h1 {
    font-weight: 400;
    font-size: 42px;
    color: #333333;
    text-align: center;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-top: 100px;
    .item {
      width: 380px;
      height: 550px;
      border: 1px solid #CCCCCC;
      padding: 16px 16px 0 16px;
      .t1{
        font-size: 18px;
        color: #474747;
        margin-top: 20px;
      }
      .text{
        font-size: 14px;
        color: #6A6A6A;
        text-align: left;
        line-height: 24px;
        margin-top: 16px;
      }
    }
  }
}
</style>